.employe-details-container .employe-item {
    width: 100%;
    margin-top: 20px;
}

.employe-details-container .employe-wrapper {
    max-width: 1200px;
    width: 100%;
}

.employe-item-image-d {
    margin-right: 15px;
}

.employe-details-container .employe-wrapper img {
    max-width: 300px;
    max-height: 350px;
    width: 100%;
    height: 100%;
    border-radius: 5px;
}

.employe-details-container .employe-item button,
.employe-details-container .employe-item .employe-item-details-btn,
.employe-details-container .employe-wrapper .employe-item-content h4,
.employe-details-container .employe-wrapper .employe-item-content p,
.employe-details-container .employe-wrapper .employe-item-content span {
    margin-bottom: 15px;
}

.employe-details-container .employe-detail-description {
    background-color: var(--color-secondary);
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 20px;
}

.employe-details-container .employe-detail-description h4 {
    color: var(--color-light);
    margin-bottom: 15px;
    font-size: 22px;
}

.employe-details-container .employe-detail-description p {
    color: var(--color-muted);
    font-size: 19px;
    font-weight: bold;
    letter-spacing: 1.5px;
}

.employe-item-statut-btn-green-details {
    background-color: var(--color-green);
    color: #fff;
}

.employe-item-statut-btn-red-details {
    background-color: var(--color-red);
    color: #fff;
}

@media (max-width: 850px) {
    .employe-details-container .employe-wrapper {
        display: flex;
        flex-direction: column;
    }

    .employe-details-container .employe-wrapper img {
        max-width: 270px;
        max-height: 300px;
    }

    .employe-details-container .employe-wrapper .employe-item-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
        margin-bottom: 10px;
        width: 100%;
    }
}

@media(max-width:575px) {
    .employe-details-container .employe-detail-description h4 {
        font-size: 20px;
    }

    .employe-details-container .employe-detail-description p {
        font-size: 15px;

    }
}