.header{
    margin-top: 20px;
    background: url('../../Assets/images/header-background.jpeg') no-repeat top right/cover;
    border-radius: var(--border-radius-primary);
    overflow: hidden;
    background-color: var(--color-secondary);
    height: 400px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.header .header-overlay{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 100%;
    padding: 50px 20px;
    z-index: 1;
}
.header .contenu{
    text-align: center;
    margin-bottom: 20px;
}
.header .contenu h1{
    font-size: 40px;
    font-weight: var(--font-weight-bold);
    color: var(--color-light);
    margin-bottom: 15px;
}
.header .contenu p{
    font-size: 18px;
    color: #fff;
    opacity: 0.8;
    letter-spacing: 1.5px;
}
.header .button-area .btn-header{
    padding: 15px 20px;
    font-size: var(--font-size-primary);
    border-radius: 4px;
    background-color: var(--color-green);
    color: var(--color-light);
    font-weight: var(--font-weight-bold);
    cursor: pointer;
}

@media (max-width: 768px){
    .header{
        height: auto;
    }
    .header .contenu h1{
        font-size: 28px;
    }
    .header .contenu p{
        font-size: 16px;
    }
    .header .button-area .btn-header{
        padding: 12px 15px;
        font-size: 18px;
        font-weight: var(--font-weight-bold);
    }
}
@media(max-width:575px){
    .header .contenu h1{
        font-size: 24px;
    }
    .header .contenu p{
        font-size: 14px;
    }
    .header .button-area .btn-header{
        padding: 10px 13px;
        font-size: 16px;
        font-weight: var(--font-weight-bold);
    }
}