.navbar {
    background-color: var(--color-secondary);
    /* position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%; */
}

.containerNav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    font-size: var(--font-size-primary);
    font-weight: var(--font-weight-bold);
    max-width: 1200px;
    margin: auto;
}

.navbar a {
    color: var(--color-light);
    cursor: pointer;
}

.navbar .link-area {
    display: flex;
    gap: 20px;
}

.navbar .form {
    display: flex;
    gap: 10px;
}
.navbar .logo{
    display: flex;
    align-items: center;
    cursor: pointer;
}
.navbar .logo img{
    width: 50px;
}
.navbar .form input {
    padding: 5px;
    max-width: 210px;
    width: 100%;
    font-size: var(--font-size-primary);
    border-radius: 4px;
}

.navbar .form button {
    padding: 5px 15px;
    font-size: var(--font-size-primary);
    font-weight: var(--font-weight-bold);
    color: var(--color-light);
    background-color: var(--color-green);
    cursor: pointer;
    border-radius: 4px;
}

.containerNav .btn-profile {
    background: none;
    color: var(--color-light);
    font-size: 15.5px;
    font-weight: bold;
    cursor: pointer;
}

.containerNav .logout-btn-user {
    padding: 5px 15px;
    font-size: var(--font-size-primary);
    font-weight: var(--font-weight-bold);
    color: var(--color-light);
    background-color: var(--color-red);
    cursor: pointer;
    border-radius: 4px;
}

@media (max-width: 768px) {

    .navbar .form,
    .containerNav {
        flex-direction: column;
    }

    .navbar a,
    .containerNav .logo,
    .containerNav .btn-profile {
        margin-bottom: 18px;
    }
    .navbar .logo a {
        margin-bottom: 0;
    }
}

@media(max-width:575px) {
    .navbar .form input {
        max-width: 180px;
        font-size: 16px;
    }

    .containerNav .logout-btn-user,
    .navbar .form button {
        font-size: 16px;
    }
}