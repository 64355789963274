.employe-item{
    display: flex;
    flex-direction: column;
    width: calc((100% / 2) - 30px);
    background-color: var(--color-secondary);
    padding: 20px;
    border-radius: 5px;
    margin-right: 60px;
    margin-bottom: 30px;
}
.employe-item button,
.employe-item .employe-item-details-btn{
    width: 100%;
    font-size: 18px;
    margin-bottom: 5px;
    padding: 8px 0;
    font-weight: var(--font-weight-bold);
    cursor: pointer;
    border-radius: 4px;
}
.employe-item .employe-item-details-btn{
    text-align: center;
    background-color: var(--color-light);
    color: #000;
    margin-top: 5px;
    position: relative;
    z-index: 1;
    overflow: hidden;
    transition: 0.7s color ease;
}
.employe-item .employe-item-details-btn::after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    z-index: -1;
    background-color: #005a7a;
    transition: 0.7s width ease;
}
.employe-item .employe-item-details-btn:hover{
    color: #fff;
}
.employe-item .employe-item-details-btn:hover::after{
    width: 100%;
}
.employe-wrapper{
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    align-items: center;
}
.employe-wrapper .img-content{
    max-width: 160px;
    max-height: 160px;
    border-radius: 50%;
    margin-right: 15px;
    width: 100%;
    height: 100%;
    position: relative;
}
.employe-wrapper img{
    max-width: 160px;
    max-height: 160px;
    width: 100%;
    border-radius: 50%;
}
.employe-wrapper .employe-item-content{
    display: flex;
    flex-direction: column;
    width: 75%;
}
.employe-wrapper .employe-item-content h4{
    font-size: 22px;
    color: var(--color-light);
    margin-bottom: 5px;
}
.employe-wrapper .employe-item-content p,
.employe-wrapper .employe-item-content span{
    color: var(--color-muted);
    font-size: 20px;
    margin-bottom: 5px;
}
.employe-wrapper .img-content .employe-item-statut-btn-green{
    background-color: var(--color-green);
    color: var(--color-light);
    position: absolute;
    top: 0;
    right: 0;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    z-index: 2;
    transform: translate(calc(-50% - 4px),50%);
}
.employe-wrapper .img-content .employe-item-statut-btn-red{
    background-color: var(--color-red);
    color: var(--color-light);
    position: absolute;
    top: 0;
    right: 0;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    z-index: 2;
    transform: translate(-50%,50%);
}
.employe-item .chat{
    position: relative;
    z-index: 1;
    overflow: hidden;
    transition: 0.5s color ease;
}
.employe-item .chat::after{
    content: '';
    position: absolute;
    width: 100%;
    height: 0%;
    bottom: 0;
    left: 0;
    z-index: -1;
    transition: 0.5s height ease;
    background: #005a7a;
}
.employe-item .chat:hover::after{
    height: 100%;
    transition: 0.5s height ease;
}
.employe-item .chat:hover{
    color: #fff;
    transition: 0.5s color ease;
}
@media (max-width: 968px){
    .employe-item{
        width: 100%;
    }
}
@media (max-width:575px){
    .voyants h2{
        font-size: 20px;
    }
    .employe-wrapper .img-content,
    .employe-wrapper img{
    max-width: 140px;
    max-height: 140px;
    }
    .employe-wrapper .employe-item-content h4{
    font-size: 20px;
    color: var(--color-light);
    margin-bottom: 5px;
    }
    .employe-wrapper .employe-item-content p,
    .employe-wrapper .employe-item-content span{
        font-size: 16px;
        margin-bottom: 5px;
    }
    .employe-item button,
    .employe-item .employe-item-details-btn{
        font-size: 16px;
        margin-bottom: 5px;
        padding: 6px 0;
        font-weight: var(--font-weight-bold);
    }
    .employe-wrapper .img-content .employe-item-statut-btn-red,
    .employe-wrapper .img-content .employe-item-statut-btn-green{
    }
}
@media (max-width:450px){
    .employe-wrapper .img-content,
    .employe-wrapper img{
        max-width: 120px;
        max-height: 120px;
    }
    .employe-wrapper .employe-item-content h4{
        font-size: 18px;
        color: var(--color-light);
        margin-bottom: 5px;
    }
    .employe-wrapper .employe-item-content p,
    .employe-wrapper .employe-item-content span{
        font-size: 14px;
        margin-bottom: 5px;
    }
    .employe-wrapper .img-content .employe-item-statut-btn-red,
    .employe-wrapper .img-content .employe-item-statut-btn-green{
        transform: translate(-50%,calc(50% - 3px));
    }
}