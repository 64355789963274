.messenger {
    height: calc(100vh - 71px);
    display: flex;
}

.chatMenu {
    width: 25%;
}

.chatBox {
    width: 75%;
}

.chatBoxWrapper {
    padding: 10px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
}
.chatBoxWrapper .timer{
    text-align: center;
    font-size: 18px;
    color: var(--color-red);
}
.chatBoxTop {
    height: 100%;
    overflow-y: scroll;
    padding-right: 10px;
    padding-bottom: 5px;
}

.chatBoxBottom {
    margin-top: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #000;
    padding-top: 5px;
}

.chatMessageInput {
    width: 80%;
    height: 90px;
    padding: 10px;
    outline: none;
    resize: none;
    border: none;
}

.chatSubmitButton {
    width: 70px;
    height: 40px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: var(--color-secondary);
    color: #fff;
}

.chatMenuWrapper {
    background-color: var(--color-secondary);
    height: 95%;
    border-radius: 10px;
    padding: 10px;
    margin-top: 20px;
    color: #fff;
}

.chatMenuWrapper .div-parent {
    position: relative;
    cursor: pointer;
}

.chatMenuWrapper .div-child {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.noConversationText {
    position: absolute;
    top: 10%;
    font-size: 50px;
    color: #ccc;
}

@media (max-width: 768px) {
    .messenger {
        flex-direction: column;
    }

    .chatBox,
    .chatMenu {
        width: 100%;
    }

    .chatBoxWrapper {
        margin-top: 20px;
        min-height: 500px;
    }

    .noConversationText {
        font-size: 40px;
    }

}

@media(max-width:575px) {
    .noConversationText {
        font-size: 30px;
    }
}