.dashbord-nav-bar {
    background-color: var(--color-secondary);
    padding: 10px;
}
.dashbord-nav-bar .container{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.dashbord-nav-bar a{
    color: #fff;
    font-size: 18px;
    font-weight: bold;
}
.dashbord-nav-bar button {
    padding: 5px 15px;
    font-size: 18px;
    font-weight: bold;
    color: #fff;
    background-color: var(--color-red);
    border-radius: 4px;
    cursor: pointer;
}

@media(max-width:575px){
    .dashbord-nav-bar .container{
        flex-direction: column;
    }
    .dashbord-nav-bar a{
        font-size: 16px;
        margin-bottom: 18px;
    }
    .dashbord-nav-bar button{
        font-size: 16px;
        padding: 5px 35px;
    }
}