body.active-modal {
    overflow-y: hidden;
}

.btn-modal {
    padding: 10px 20px;
    display: block;
    margin: 100px auto 0;
    font-size: 18px;
}

.modal,
.overlay {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
}

.overlay {
    background: rgba(49, 49, 49, 0.8);
    z-index: 99;
}
.modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: #f1f1f1;
    padding: 40px;
    border-radius: 12px;
    max-width: 800px;
    min-width: 300px;
    width: 100%;
    z-index: 99;
}

.close-modal {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px 7px;
}

.formBox input {
    background: none;
}

.modal-content h3 {
    color: var(--color-secondary);
    font-size: 1.5rem;
}

.formBox {
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-top: 2.5rem;
}

.formBox .inputBox {
    position: relative;
    margin: 0 0 2.5rem 0;
}

.formBox .inputBox:last-child {
    margin: 0;
}

.formBox .inputBox.w50 {
    width: 47%;
}

.formBox .inputBox.w100 {
    width: 100%;
}

.formBox .inputBox input {
    width: 100%;
    resize: none;
    padding: 0.25rem;
    font-size: 1.1rem;
    font-weight: 300;
    color: var(--color-gray);
    border-bottom: 1px solid var(--color-muted);
}

.formBox .inputBox span {
    position: absolute;
    left: 0.25rem;
    padding: 0.125rem 0;
    font-size: 1.1rem;
    color: var(--color-secondary);
    transition: 0.5s;
    pointer-events: none;
}

.formBox .verifier-pseudo.exist {
    border-bottom: 1px solid var(--color-red);
}

.formBox .verifier-pseudo.not-exist {
    border-bottom: 1px solid var(--color-green);
}

.formBox .inputBox input:focus~span,
.formBox .inputBox input:valid~span,
.formBox .inputBox textarea:focus~span,
.formBox .inputBox textarea:valid~span {
    transform: translateY(-1.5rem);
    font-size: 0.8rem;
    color: var(--color-secondary);
}

.formBox .inputBox textarea {
    width: 100%;
    resize: none;
    padding: 0.25rem;
    font-size: 1.1rem;
    font-weight: 300;
    outline: none;
    border: none;
    background-color: transparent;
    min-height: 100px;
    border-bottom: 1px solid var(--color-muted);
}

.formBox .inputBox.w100 {
    width: 100%;
}

button.submit-btn,
button.close-modal {
    padding: 15px 20px;
    font-size: var(--font-size-primary);
    border-radius: 4px;
    background-color: var(--color-green);
    color: var(--color-light);
    font-weight: var(--font-weight-bold);
    cursor: pointer;
    width: 20%;
}

button.submit-btn {
    width: 35%;
}

@media (max-width: 768px) {
    .modal-content {
        width: 80%;
    }

    .modal-content h3 {
        font-size: 1.3rem;
    }
}

@media(max-width:575px) {
    .modal-content {
        width: 95%;
    }

    .modal-content h3 {
        font-size: 1.1rem;
    }

    .formBox .inputBox input,
    .formBox .inputBox span {
        font-size: 0.9rem;
    }

    button.submit-btn,
    button.close-modal {
        padding: 10px 15px;
        font-size: 15px;
        width: 30%;
    }

    button.submit-btn {
        width: 30%;
    }
}